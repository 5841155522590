<template lang="pug">
  b-card.manager-accounts-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(v-if="action === 'update'" :tabs='tabs')
    b-card-header.bg-transparent.border-0(v-else)
      h5.m-0
        | {{ $t('activerecord.models.account.one') }}
    b-form.form-wc.form-account-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-account-email(
              :label="$t('activerecord.attributes.account.email')"
              label-for='account-email'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#account-email.form-input-first.shadow-sm.is-required(
                name='email'
                type='email'
                autocomplete='email'
                :placeholder="$t('account.placeholders.email')"
                :state='formValidationState($v.form.data.attributes.email)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.email.$model'
                aria-describedby='account-email-feedback'
                trim)
              wc-forms-if#account-email-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.email'
                :remote="formRemoteInvalidFeedback('email')"
                :validators='{ required: {}, regExp: {}, maxLength: {} }')

            b-form-group#form-account-username(
              :label="$t('activerecord.attributes.account.username')"
              label-for='account-username'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#account-username.form-input-middle.shadow-sm(
                name='username'
                type='text'
                autocomplete='username'
                :placeholder="$t('account.placeholders.username')"
                :state='formValidationState($v.form.data.attributes.username)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.username.$model'
                aria-describedby='account-username-feedback'
                trim)
              wc-forms-if#account-username-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.username'
                :remote="formRemoteInvalidFeedback('username')"
                :validators='{ regExp: {}, minLength: {}, maxLength: {}, takenOrNotAllowed: {} }')

            b-form-group#form-account-password(
              :label="$t('activerecord.attributes.account.password')"
              label-for='account-password'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#account-password.form-input-middle.shadow-sm(
                name='password'
                type='password'
                autocomplete='new-password'
                :placeholder="$t('account.placeholders.password')"
                :state='formValidationState($v.form.data.attributes.password)'
                :class="action === 'create' ? 'is-required' : ''"
                @focus.native='formChange'
                v-model='$v.form.data.attributes.password.$model'
                aria-describedby='account-password-feedback'
                trim)
              wc-forms-if#account-password-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.password'
                :remote="formRemoteInvalidFeedback('password')"
                :validators='{ required: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-account-password_confirmation(
              :label="$t('activerecord.attributes.account.password_confirmation')"
              label-for='account-password_confirmation'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#account-password_confirmation.form-input-middle.shadow-sm(
                name='password_confirmation'
                type='password'
                autocomplete='new-password-confirmation'
                :placeholder="$t('account.placeholders.password_confirmation')"
                :state='formValidationState($v.form.data.attributes.password_confirmation)'
                :class="action === 'create' ? 'is-required' : ''"
                @focus.native='formChange'
                v-model='$v.form.data.attributes.password_confirmation.$model'
                aria-describedby='account-password_confirmation-feedback'
                trim)
              wc-forms-if#account-password_confirmation-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.password_confirmation'
                :remote="formRemoteInvalidFeedback('password_confirmation')"
                :validators='{ required: {}, sameAsPassword: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-account-locale(
              :label="$t('activerecord.attributes.account.locale')"
              label-for='account-locale'
              label-cols-md='2'
              label-align-md='right')
              multiselect#account-locale.form-input-middle.shadow-sm.custom-select.is-required(
                name='locale'
                v-model='accountLocaleSelected'
                :options='accountLocaleOptions'
                :class='formValidationClass($v.form.data.attributes.locale)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('account.placeholders.locale')"
                label='label'
                track-by='value'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options') }}
              wc-forms-if#account-locale-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.locale'
                :remote="formRemoteInvalidFeedback('locale')"
                :validators='{ required: {} }')

            b-form-group#form-account-time_zone(
              :label="$t('activerecord.attributes.account.time_zone')"
              label-for='account-time_zone'
              label-cols-md='2'
              label-align-md='right')
              multiselect#account-time_zone.form-input-last.shadow-sm.custom-select.is-required(
                name='time_zone'
                v-model='accountTimeZoneSelected'
                :options='accountTimeZoneOptions'
                :class='formValidationClass($v.form.data.attributes.time_zone)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('account.placeholders.time_zone')"
                label='label'
                track-by='value'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options') }}
              wc-forms-if#account-time_zone-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.time_zone'
                :remote="formRemoteInvalidFeedback('time_zone')"
                :validators='{ required: {} }')

            b-form-group#form-account-skip_confirmation(
              v-if="action === 'create'"
              :label="$t('activerecord.attributes.account.skip_confirmation')"
              label-for='account-skip_confirmation'
              label-cols-md='2'
              label-align-md='right')
              b-form-checkbox#account-skip_confirmation.d-flex.align-items-center(
                name='skip_confirmation'
                value='true'
                unchecked-value='false'
                size='lg'
                v-model='$v.form.data.attributes.skip_confirmation.$model'
                switch)

            b-form-group#form-account-active(
              v-if="action === 'update'"
              :label="$t('activerecord.attributes.shared.active')"
              label-for='account-skip_confirmation'
              label-cols-md='2'
              label-align-md='right')
              b-form-checkbox#account-active.d-flex.align-items-center(
                name='active'
                value='true'
                unchecked-value='false'
                size='lg'
                v-model='$v.form.data.attributes.active.$model'
                switch)

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-account-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/accounts/shared/tabs'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiAccounts from '@services/api/manager/accounts'
import { Form, mapSelects } from '@common/form'
import { regExp, boolean } from '@common/form/validations'
import { required, requiredIf, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-accounts-form',
  mixins: [Form, Tabs],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'accountLocale',
        default: 'attributes.locale',
        attribute: 'attributes.locale',
        collection: { data: 'available_locales.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    ...mapSelects([
      {
        name: 'accountTimeZone',
        default: 'attributes.time_zone',
        attribute: 'attributes.time_zone',
        collection: { data: 'time_zones.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    accountUpdateQuery() {
      return this.action === 'update' ? { id: this.account_id } : {}
    },
    apiParams() {
      return {
        get: [{ id: this.account_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.account_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        uniqueUsername: [
          {},
          {
            ...this.accountUpdateQuery,
            term: this.$getDeep(this.form, 'data.attributes.username'),
          },
          { responseErrorRaw: true },
        ],
      }
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('account-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            email: {
              required,
              regExp: regExp(this.$wc.conf.regex.email_browser),
              maxLength: maxLength(this.$wc.conf.limits.max_email),
              remote: () => this.formRemoteValid('email'),
            },
            username: {
              regExp: regExp(this.$wc.conf.regex.username_browser),
              minLength: minLength(this.$wc.conf.limits.min_username),
              maxLength: maxLength(this.$wc.conf.limits.max_username),
              takenOrNotAllowed: async () => {
                /* Validate only if. */
                if (
                  this.validateIf('username', ['regExp', 'minLength', 'maxLength'], {
                    if: 'invalid',
                  })
                )
                  return true

                /* Validate remote. */
                return await new Promise((resolve) => {
                  clearTimeout(this.form.timer.uniqueUsername)
                  this.form.timer.uniqueUsername = setTimeout(resolve, 600)
                }).then(() => {
                  if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
                  this.apiRequest = this.formRequest('uniqueUsername')
                  return this.apiRequest.promise // eslint-disable-line promise/no-nesting
                    .then(() => {
                      return true
                    })
                    .catch((error) => {
                      return error.response && error.response.status === 422 ? false : true
                    })
                })
              },
              remote: () => this.formRemoteValid('username'),
            },
            password: {
              required: requiredIf(() => {
                return this.action === 'create'
              }),
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password'),
            },
            password_confirmation: {
              required: requiredIf(() => {
                return this.action === 'create'
              }),
              sameAsPassword: sameAs(() => {
                return this.form.data.attributes.password
              }),
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password_confirmation'),
            },
            locale: {
              required,
              remote: () => this.formRemoteValid('locale'),
            },
            time_zone: {
              required,
              remote: () => this.formRemoteValid('time_zone'),
            },
            skip_confirmation: {
              boolean,
            },
            active: {
              boolean,
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiAccounts,
      apiModel: 'account',
      account_id: this.$route.params.account_id,
    }
  },
}
</script>

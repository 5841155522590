import { render, staticRenderFns } from "./WcCardTabs.vue?vue&type=template&id=63bb68d8&lang=pug&"
import script from "./WcCardTabs.vue?vue&type=script&lang=js&"
export * from "./WcCardTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template lang="pug">
  b-card-header.wc-card-tabs
    b-tabs(nav-class='card-header-tabs')
      template(v-slot:tabs-end)
        template(v-for='tab in tabs')
          b-nav-item(
            v-if='!tab.hide'
            active-class='active'
            :to='tab.route')
            | {{ tab.name }}
</template>

<script>
export default {
  name: 'wc-card-tabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

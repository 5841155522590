import WcCardTabs from '@components/shared/WcCardTabs'

const AccountTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('activerecord.models.account.one'),
          route: { name: 'manager-accounts-edit', params: { id: this.account_id } },
        },
        {
          name: this.$t('activerecord.models.role.other'),
          route: { name: 'manager-accounts-roles-edit', params: { account_id: this.account_id } },
        },
      ]
    },
  },
}

export default AccountTabs
